import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","no-click-animation":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:{ 'add-attached': _vm.isActivatorText, 'default-btn': true },attrs:{"depressed":"","text":_vm.isActivatorText,"icon":_vm.isIcon,"color":"primary"}},'v-btn',attrs,false),on),[(!_vm.isLoading)?_vm._t("activator-text"):_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}})],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"dialog__card",attrs:{"flat":"","rounded":""}},[_vm._t("content",null,{"close":_vm.closeDialog,"reset":_vm.reset}),_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.centeredDialog),callback:function ($$v) {_vm.centeredDialog=$$v},expression:"centeredDialog"}},[_c(VCard,{staticClass:"centered-dialog__card"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('h4',[_vm._v(_vm._s(_vm.title))])])],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('p',{staticClass:"caption font-weight-bold"},[_vm._v(_vm._s(_vm.description))])])],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","color":"default-btn primary"},on:{"click":function($event){_vm.centeredDialog = false}}},[_vm._v(" No ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","dark":"","color":"primary default-btn"},on:{"click":_vm.closeCenteredDialog}},[_vm._v(" Sí ")])],1)],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }