import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px"},on:{"click:outside":function($event){return _vm.$emit('close')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"centered-dialog__card"},[_vm._t("content"),_c(VCardActions,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","color":"default-btn primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","color":"primary default-btn","disabled":!_vm.formValid,"loading":_vm.buttonLoader},on:{"click":function($event){return _vm.$emit(_vm.functionToCall)}}},[_vm._v(" Aceptar ")])],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }