<template>
  <div>
    <v-dialog
      max-width='600px'
      v-model="dialog"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="centered-dialog__card">
          <v-stepper class="stepperShadow" v-model="stepper">
            <!-- <v-stepper-header> -->
              <v-row class="alignHeader" align="center" justify="center" no-gutters>
                <v-col cols="auto">
                  <h3>{{ signAndCloseOrUpdate() }}</h3>
                  <h5>Paso {{stepper}} de 2</h5>
                </v-col>
              </v-row>
            <!-- </v-stepper-header> -->
            <v-stepper-content class="my-0 stepper-height" step="1">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h5 color="dark">1. Configura la fecha de vigencia del documento.</h5>
                  <p class="sub-info"> Cuando el contrato llegue a su vencimiento, este podrá ser renovado.</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <DateField
                    :dateFromValidity="contract.startDate ? contract.startDate : null"
                    :label="'Fecha de inicio'"
                    :type="0"
                    :contract="contract"
                    @datesValidator="datesValidator"
                  >
                  </DateField>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <DateField
                    :dateFromValidity="contract.endDate ? contract.endDate : null"
                    :label="'Fecha de finalización'"
                    :type="1"
                    :contract="contract"
                    @datesValidator="datesValidator"
                  >
                  </DateField>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <DateField
                    :dateFromValidity="contract.reminderDate ? contract.reminderDate : null"
                    :label="'Fecha de recordatorio'"
                    :type="2"
                    :contract="contract"
                    @datesValidator="datesValidator"
                  >
                  </DateField>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content class="stepper-height" step="2">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h5 color="dark">2. Sube el contrato firmado para finalizar.</h5>
                  <p class="sub-info"> Al firmar aceptas que la documentación del contrato es correcta.</p>
                </v-col>
              </v-row>
              <v-row
                class="align-btn"
                align="center"
                justify="center"
              >
                <v-col cols="12" md="12">
                  <FileUploader
                    ref="signedDocumentRef"
                    :detailFileContract="false"
                    extension="pdf, doc, docx"
                    :multiple="false"
                    :isValidity="true"
                    labelText="Subir documento firmado"
                    @upload-file="uploadFile($event)"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        <v-card-actions>
          <v-row align="center" justify="center">
            <div v-if="stepper === 1">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="default-btn primary"
                  @click="$emit('close')"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </div>
            <div v-else>
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="default-btn primary"
                  @click="stepper = 1"
                >
                  Regresar
                </v-btn>
              </v-col>
            </div>
            <div v-if="stepper === 1">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="primary default-btn"
                  :disabled="!buttonDisabled"
                  @click="stepper = 2"
                >
                  Siguiente
                </v-btn>
              </v-col>
            </div>
            <div v-else>
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="primary default-btn"
                  :loading="submitLoading"
                  @click="submitValidity"
                >
                  Completar
                </v-btn>
              </v-col>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import DateField from '../Base/DateField'
import FileUploader from '@/components/Base/FileUploader'
import { colors } from '@/utils/colors'
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Validity',
  components: { DateField, FileUploader },
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    items: [],
    foderSearch: '',
    stepper: 1,
    date: new Date().toISOString().substr(0, 10),
    signedFile: [],
    areDatesValid: false,
    buttonDisabled: false,
    submitLoading: false
  }),
  created () {
  },
  computed: {
    // ...mapState('contract', ['folders'])

  },

  methods: {
    ...mapActions('contract', ['setValidity']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    datesValidator (item) {
      if (this.contract.startDate != null && this.contract.endDate != null && this.contract.reminderDate === null) {
        if (this.contract.startDate > this.contract.endDate) {
          this.buttonDisabled = false
          this.setSnackbar({
            text: 'La fecha de inicio no puede ser mayor a la fecha de finalización',
            timeout: 5000,
            showing: true,
            color: colors.error
          })
        } else {
          this.validateButtonDisabled()
        }
      } else if (this.contract.reminderDate != null && this.contract.endDate != null && this.contract.startDate != null) {
        if (this.contract.reminderDate > this.contract.endDate || this.contract.reminderDate < this.contract.startDate) {
          this.buttonDisabled = false
          this.setSnackbar({
            text: 'La fecha de recordatorio debe de estar dentro del rango de inicio y finalización',
            timeout: 5000,
            showing: true,
            color: colors.error
          })
        } else {
          this.validateButtonDisabled()
        }
      }
    },

    uploadFile (file) {
      this.signedFile = file
    },

    submitValidity () {
      let request = {}
      if (this.signedFile.length > 0) {
        request = {
          document: this.contract.iddocument,
          start: moment(this.contract.startDate).format('YYYY-MM-DD hh:mm:ss'),
          end: moment(this.contract.endDate).format('YYYY-MM-DD hh:mm:ss'),
          reminder: moment(this.contract.reminderDate).format('YYYY-MM-DD hh:mm:ss'),
          file: this.signedFile[0]
        }
      } else {
        request = {
          document: this.contract.iddocument,
          start: moment(this.contract.startDate).format('YYYY-MM-DD hh:mm:ss'),
          end: moment(this.contract.endDate).format('YYYY-MM-DD hh:mm:ss'),
          reminder: moment(this.contract.reminderDate).format('YYYY-MM-DD hh:mm:ss')
        }
      }
      this.submitLoading = true
      this.setValidity(request)
        .then(response => {
          this.signedFile = []
          this.$refs.signedDocumentRef.cleanFiles()
          this.submitLoading = false
          this.stepper = 1
          this.$emit('close')
          this.$emit('update-info')
          this.setSnackbar({
            text: 'Contrato actualizado correctamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          console.log(error)
          this.submitLoading = false
          this.stepper = 1
          this.$emit('close')
          this.setSnackbar({
            text: 'No se pudo actualizar el contrato',
            timeout: 3000,
            showing: true,
            color: colors.error
          })
        })
    },

    validateButtonDisabled () {
      if (this.contract.startDate !== null && this.contract.endDate !== null && this.contract.reminderDate !== null) {
        this.buttonDisabled = true
      } else {
        this.buttonDisabled = false
      }
    },

    signAndCloseOrUpdate () {
      return this.contract.status !== 'Finalizado' ? 'Firmar y cerrar' : 'Actualizar vigencias'
    }
  }
}
</script>
<style lang="scss">
.alignHeader {
  text-align: center;
}
.stepperShadow {
  box-shadow: none;
}
.stepper-height {
  height: 320px;
}

@media screen and (max-width: 600px) {
  .stepper-height {
    height: auto;
  }
}
</style>
