import { profiles } from '@/utils/profiles'

export default {

  methods: {
    isLegalProfile () {
      const currentProfile = localStorage.getItem('profile')
      const legalProfile = profiles.find(element => element.name === 'Legal')
      const found = profiles.find(element => element.name === currentProfile)
      return legalProfile.permission === found.permission
    },
    isClientProfile () {
      return localStorage.getItem('profile') === 'Client'
    },
    isManagerProfile () {
      return localStorage.getItem('profile') === 'Manager'
    }
  }
}
