<template>
  <v-row>
    <v-col
      cols="auto"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :min="min"
          :max="max"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updateParent"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => ''
    },
    type: {
      type: Number,
      default: () => 0
    },
    contract: {
      type: Object,
      default: () => {}
    },
    dateFromValidity: {
      type: String,
      default: () => null
    },
    min: {
      type: String,
      default: () => undefined
    },
    max: {
      type: String,
      default: () => undefined
    }
  },
  data: () => ({
    menu: false,
    date: null
  }),

  // type 0 = startdate, type 1 = endDate, typ2 2 = reminderDate
  created () {
    if (this.dateFromValidity !== null) {
      this.date = this.dateFromValidity
    }
  },
  methods: {
    updateParent () {
      this.$refs.menu.save(this.date)
      if (this.type === 0) {
        this.contract.startDate = this.date
      } else if (this.type === 1) {
        this.contract.endDate = this.date
      } else if (this.type === 2) {
        this.contract.reminderDate = this.date
      }
      this.$emit('datesValidator', this.contract)
    }
  }
}
</script>

<style>

</style>
