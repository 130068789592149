<template>
  <div class="main-bg pa-0">
    <v-row justify="center">
      <v-col class="mb-4" cols="5">
        <v-form ref="configurationContract" v-model="form.isValid">
          <label class="form-label" for="name">
            <!-- Nombre del Contrato -->
            Nombre
          </label>
          <v-text-field
            class="form-field mt-2"
            :rules="contractNameRules"
            name="name"
            outlined
            single-line
            background-color="white"
            v-model="form.contractName"
          />
          <label class="form-label" for="type">
            <!-- Elige la categoría del contrato -->
            Elige la categoría
          </label>
          <v-select
            class="form-field mt-2"
            :rules="typeContractRules"
            name="type"
            :items="templateTypes"
            item-text="name"
            item-value="id"
            outlined
            single-line
            background-color="white"
            v-model="form.typeContract"
            :disabled='(hideSelects || createFromAttach) || createFromAddendum ? true : false'
            @change="onChangeTypeContract"
            clearable
          />
          <label class="form-label" for="type">
            <!-- Elige la plantilla del contrato -->
            Elige la plantilla
          </label>
          <v-select
            class="form-field mt-2"
            :rules="templateRules"
            name="type"
            :items="templateList"
            item-value="idtemplate"
            outlined
            single-line
            background-color="white"
            v-model="form.template"
            return-object
            :disabled='hideSelects'
            no-data-text="No hay plantillas disponibles"
            @change="changeFolderCountry"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.title }} - {{ data.item.country }}
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.title }} - {{ data.item.country }}
            </template>
          </v-select>
          <div v-if="!createFromAttach && !createFromAddendum">
            <label class="form-label" for="type">
              Guardar en ...
            </label>
            <v-select
              class="form-field mt-2"
              name="type"
              :items="foldersFiltered"
              item-value="id"
              outlined
              single-line
              background-color="white"
              v-model="form.folder"
              no-data-text="No hay carpetas creadas"
              :disabled="disabledFolder"
              clearable
            >
              <template slot="selection" slot-scope="data">
                <v-icon class="mr-2" color="primary"> mdi-folder </v-icon>
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} - {{ data.item.country }}
              </template>
              <template slot="item" slot-scope="data">
                <v-icon class="mr-2"> mdi-folder </v-icon>
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} - {{ data.item.country }}
              </template>
            </v-select>
            <div v-if="showManagerSelector()">
              <v-row no-gutters align="center" justify="space-between">
                <v-col cols="auto">
                  <label class="form-label" for="type">
                    Compartir con gerentes
                  </label>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="isSharedToManager"
                    :disabled="templateList.length === 0"
                    class=""
                    inset
                    dense
                    flat
                  ></v-switch>
                </v-col>
              </v-row>
              <v-autocomplete
                v-if="isSharedToManager"
                class="form-field mt-2"
                name="type"
                :items="managerList"
                v-model="form.shareToManagers"
                item-text="name"
                item-value="user"
                outlined
                single-line
                background-color="white"
                label="Seleccione los gerentes"
                clearable
                multiple
                :loading="selectManagerLoader"
              />
            </div>
            <div v-if="!isFinanceProfile()">
              <v-row no-gutters align="center" justify="space-between">
                <v-col cols="auto">
                  <label class="form-label" for="type">
                    Compartir con finanzas
                  </label>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="form.isFinances"
                    :disabled="financeList.length === 0"
                    class=""
                    inset
                    dense
                    flat
                  ></v-switch>
                </v-col>
              </v-row>
              <v-autocomplete
                v-if="form.isFinances"
                class="form-field mt-2"
                name="type"
                :items="financeList"
                v-model="form.financeUsers"
                item-text="name"
                item-value="user"
                outlined
                single-line
                background-color="white"
                label="Seleccione los usuarios de finanzas"
                clearable
                multiple
                :loading="selectManagerLoader"
              />
            </div>
            <FileUploader :cmProposal="true" :with-switch="true" label-text="Adjuntar propuesta comercial" extension="pdf"></FileUploader>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileUploader from '@/components/Base/FileUploader'
import { mapState, mapActions } from 'vuex'

import Bus from '@/helpers/bus'

export default {
  name: 'ContractConfiguration',
  components: {
    FileUploader
  },

  props: {
    type: {
      type: Object,
      required: false,
      default: () => null
    },
    form: {
      type: Object,
      required: true
    },
    attachFromDetail: {
      type: Object,
      required: false,
      default: () => {}
    },
    createFromAttach: {
      type: Boolean,
      default: () => false
    },
    createFromAddendum: {
      type: Boolean,
      default: () => false
    },
    generateFromTemplate: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      contractNameRules: [
        v => !!v || 'El nombre del contrato es requerido'
      ],
      typeContractRules: [
        v => !!v || 'El tipo de contrato es requerido'
      ],
      templateRules: [
        v => !!v || 'El tipo de contrato es requerido'
      ],
      templateList: [],
      templateListLoader: false,
      hideSelects: false,
      foldersFiltered: [],
      disabledFolder: true,
      isSharedToManager: false,
      managerList: [],
      financeList: [],
      selectManagerLoader: false
    }
  },

  computed: {
    ...mapState(['templateTypes']),
    ...mapState('template', ['templates']),
    ...mapState('contract', ['folders']),
    ...mapState(['folderSelection']),
    ...mapState(['allFolders'])
  },

  created () {
    this.foldersFiltered = this.allFolders
    if (this.allFolders.length === 0) {
      this.obtainAllFolders()
    }
    if (this.createFromAttach || this.createFromAddendum) {
      this.getAttachmentTemplateList()
    } else {
      this.onChangeTypeContract()
    }
    if (this.type != null && this.generateFromTemplate) {
      this.templateList = this.templates
      this.form.typeContract = this.type.templateType
      this.form.template = this.type.idtemplate
      // this.form.template.country = this.templates.filter(v => v.idtemplate === this.type.idtemplate)[0].country
      this.hideSelects = true
      // this.onChangeTypeContract()
    }

    Bus.$on('reset-contract-form', () => {
      if (this.$refs.configurationContract && !this.generateFromTemplate) {
        this.$refs.configurationContract.resetValidation()
        this.disabledFolder = true
      }
    })
    Bus.$on('upload-file', (file) => {
      this.form.cmProposal = file
    })

    this.getFinanceUsers()
      .then(response => {
        this.financeList = response.data
      })
  },
  methods: {
    ...mapActions('contract', [
      'getAttachmentTemplates', 'getAddendumTemplates', 'getManagers',
      'getFinanceUsers'
    ]),
    ...mapActions(['getAllFolders']),
    ...mapActions('template', ['getFilterTemplates', 'getTemplates']),

    onChangeTypeContract () {
      this.templateList = []
      if (this.form.typeContract !== undefined) {
        this.templateListLoader = true
        const profile = localStorage.getItem('profile')
        let data = {}
        if (profile === 'Legal') {
          data = {
            type_template: this.form.typeContract,
            status: 1
          }
        } else {
          data = {
            country: localStorage.getItem('country'),
            type_template: this.form.typeContract,
            status: 1
          }
        }
        this.getFilterTemplates(data).then(() => {
          if (this.folderSelection.id === undefined) {
            this.templateList = this.templates
          } else {
            this.templateList = this.templates.filter(v => v.country === this.folderSelection.country)
          }
          this.templateListLoader = false
          if (this.form.template === '' || this.form.template === null) {
            this.disabledFolder = true
          } else {
            this.disabledFolder = false
          }
          // this.foldersFiltered = this.foldersFiltered.filter(v => v.country === this.form.template.country)
          this.obtainAllFolders()
          if (this.folderSelection.id !== undefined) {
            this.form.folder = this.folderSelection.id
            this.disabledFolder = true
          }
          this.selectManagerLoader = true

          var managerCountry = ''
          if (this.type !== null && this.type !== undefined) {
            managerCountry = this.type.country
          } else if (this.form.template !== undefined && this.form.template !== null) {
            managerCountry = this.form.template.country
          }
          if (this.type !== null && this.form.template !== null) {
            this.getManagers({ country: managerCountry })
              .then(response => {
                this.managerList = response.data
                this.selectManagerLoader = false
              })
          }
        })
      }
    },

    getAttachmentTemplateList () {
      const request = {
        document: this.attachFromDetail.idDocumentToAttach
      }
      if (this.createFromAttach) {
        this.getAttachmentTemplates(request)
          .then(response => {
            this.form.typeContract = this.attachFromDetail.idCategory
            this.templateList = response.data
          })
      } else if (this.createFromAddendum) {
        this.getAddendumTemplates(request)
          .then(response => {
            this.form.typeContract = this.attachFromDetail.idCategory
            this.templateList = response.data
          })
      }
    },

    changeFolderCountry () {
      if (this.form.template !== '' && this.form.template !== null) {
        this.foldersFiltered = this.allFolders.filter(v => v.country === this.form.template.country)
      }
      this.selectManagerLoader = true
      this.getManagers({ country: this.form.template.country })
        .then(response => {
          this.managerList = response.data
          this.selectManagerLoader = false
        })
    },

    isFinanceProfile () {
      return localStorage.getItem('profile') === 'Finance'
    },

    showManagerSelector () {
      return localStorage.getItem('profile') === 'Legal'
    },

    obtainAllFolders () {
      this.getAllFolders()
        .then(response => {
          if (this.type !== null && this.type !== undefined) {
            this.foldersFiltered = response.filter(v => v.country === this.type.country)
          } else if (this.form.template !== undefined && this.form.template !== null) {
            this.foldersFiltered = response.filter(v => v.country === this.form.template.country)
          }
        })
    }
  }
}
</script>

<style>

</style>
