<template>
  <div>
    <v-navigation-drawer
      v-model="showDrawer"
      absolute
      bottom
      temporary
      :right="true"
    >
    <div v-if="notifications.length > 0">
      <v-row no-gutters class="scroll-detail-obs hide-scroll">
        <div class="my-2" v-for="notification in notifications" :key="notification.id">
          <v-row class="mt-3" no-gutters>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <h5> {{ notification.title }}</h5>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <span> {{ notification.notification }} </span>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <span class="sub-info"> {{ notification.created }} </span>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </div>
    <div v-else class="center mt-4">
      <h5>No hay notificaciones</h5>
    </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContractNotifications',
  components: {},
  props: {
    contractId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    showDrawer: false,
    notifications: []
  }),
  watch: {
    notifications: {
      handler (val) {
      },
      deep: true
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    ...mapActions('contract', ['getContractNotifications']),

    obtainContractNotifications () {
      const request = {
        document: this.contractId
      }
      this.getContractNotifications(request)
        .then(response => {
          this.showDrawer = true
          this.notifications = response.data
          this.addnotificationLoader = false
        })
        .catch(() => {
          this.addnotificationLoader = false
        })
    }
  }
}
</script>

<style>
.center-btn {
  text-align: center;
}
.scroll-detail-obs {
  min-height: 200px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
