<template>
  <div class="detail-container fill-height" v-if="!isLoading">
    <v-row no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <v-btn icon color="black"  to="/portal/contracts" exact>
          <img src="@/assets/icons/ic_keyboard_normal.svg" alt="">
        </v-btn>
        <span class="pointer my-folders-text" @click="$router.push('/portal/contracts')"> Mis carpetas / </span>
        <span v-if="contract.client">
          {{contract.client}} /
        </span>
        <span>
          {{contract.title}} - {{contract.country}}
          <v-tooltip bottom v-if="userProfile !== 'Client' && userProfile !==  'Finance'">
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="vertical-align: text-bottom;"
                  color="primary"
                  @click="shareDialog = true"
                  v-bind="attrs"
                  v-on="on"
                > mdi-share-outline </v-icon>
            </template>
            <span>Compartir contrato</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                class="subtitle-action"
                :content="contract.notifications"
                :value="contract.notifications"
                color="secondary"
                overlap
              >
                <v-icon
                  style="vertical-align: text-bottom;"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="showDrawerNotifications()"
                >
                  mdi-bell-outline
                </v-icon>
              </v-badge>
            </template>
            <span>Ver notificaciones</span>
          </v-tooltip>
        </span>
      </v-col>
      <v-col cols="auto">
        <v-btn
        dark
        color="primary"
        class="default-btn"
        @click="() => {infoDialog = true}"
      >
        Ver estado de revisión
      </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-1">
      <v-col cols="auto">
        <span class="contractDetailHead">Plantilla: {{contract.templateName}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-1">
      <v-col cols="auto">
        <span class="contractDetailHead">{{contract.templateType}} - Creado por {{contract.userCreated}} el {{contract.created}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between" align="center">
      <v-col class="pb-1" cols="12" sm="4" md="4">
        <div v-if="contract.idstatus === 4">
          <v-row :class="colorClass.color" no-gutters align="center">
            <v-col cols="auto" :class="colorClass.strong">
            </v-col>
            <v-col :class="colorClass.ligth" class="expiration-border">
              <div class="expiration-span"> {{ colorClass.text }} </div>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="expiration-text" no-gutters align="center">
            <v-col cols="auto" class="first">
            </v-col>
            <v-col class="second expiration-border">
              <h5 class="expiration-span"> El contrato no tiene fechas configuradas</h5>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="auto" v-if="contract.commercialFile">
        <span class="pointer change-state" @click="showCmProposal"> Ver propuesta comercial </span>
      </v-col>
    </v-row>
    <div>
      <v-row no-gutters align="center">
        <v-col cols="12" :md="showAddendumInfo.show ? 8 : 12" :sm="showAddendumInfo.show ? 8 : 12">
          <v-card class="card-state mr-1">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="12" md="auto" sm="auto">
                <v-icon color="primary"> {{contract.icon}} </v-icon>
                <span class="align-icon"> Estado: {{contract.status}}</span>
              </v-col>
              <v-col cols="12" md="auto" sm="auto">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="setBackToPending()"
                          color="primary"
                          @click="changeContractState"
                          v-bind="attrs"
                          v-on="on"
                        > mdi-pencil-outline </v-icon>
                    </template>
                    <span>Actualizar estado</span>
                  </v-tooltip>
                <span> Revisión: {{contract.areaReview}} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="4" v-if="showAddendumInfo.show">
          <v-card class="card-state ml-4">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="auto">
              </v-col>
              <v-col cols="12" md="auto" sm="auto">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="contract.status !== 'Cancelado' && contract.status !== 'Finalizado' && contract.status !== 'Aprobado'"
                          color="primary"
                          @click="changeContractState"
                          v-bind="attrs"
                          v-on="on"
                        > mdi-pencil-outline </v-icon>
                    </template>
                    <span>Actualizar estado</span>
                  </v-tooltip>
                <span> Adendas en proceso: {{addendum.total}} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class="my-4">
        <DetailCards
          :contract="contract"
          :files="files"
          :attachments="attachments"
          :addendums="addendums"
          @update-detail-files="updateDetailFiles"
        />
      </div>
    </div>
    <v-row justify="center" v-if="isLegalProfile() || isManagerProfile()">
      <v-btn
        v-if="contract.status !== 'Cancelado'"
        color="primary"
        class="default-btn"
        :disabled="contract.status === 'Aprobado' || contract.status === 'Finalizado' ? false: true"
        @click="validityDialog = true"
      >
        {{ signAndCloseOrUpdate() }}
      </v-btn>
    </v-row>
    <ConfirmDialog
      functionToCall='updateState'
      :dialog="statusDialog"
      :buttonLoader="updateButtonLoader"
      :formValid="formIsValid"
      @close="closeDialog"
      @updateState="updateState">
      <template slot='content'>
        <v-form ref="changeStatusForm">
          <h4 class="pa-8 centerH4">Actualiza el estado de revisión</h4>
            <v-row>
              <v-col cols="12" md="12" class="pl-16 pr-16 pt-2 pb-0">
                <span class="file-name"> Estado </span>
                <v-select
                  :items="statusList"
                  item-text="name"
                  item-value="idstatusreview"
                  v-model="statusForm.statusItem"
                  label="Seleccione estado"
                  solo
                  outlined
                  clearable
                  dense
                  clear-icon="mdi-close-circle"
                  flat
                  hide-details="auto"
                  return-object
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="12" class="pl-16 pr-16" v-if="showRejectJustifications">
                <span class="file-name"> Motivo de rechazo </span>
                <v-select
                  class="pb-3"
                  :items="rejectionList"
                  item-text="text"
                  item-value="idjustification"
                  v-model="statusForm.rejectionItem"
                  label="Seleccione motivo de rechazo"
                  solo
                  outlined
                  clearable
                  dense
                  clear-icon="mdi-close-circle"
                  flat
                  hide-details="auto"
                  return-object
                >
                </v-select>
                <span class="file-name"> Observación </span>
                <v-textarea
                  class="pb-3"
                  label="Escribe una observación"
                  v-model="statusForm.observation"
                  outlined
                  single-line
                  background-color="white"
                  :rules="[v => (v || '' ).length <= 500 || 'La descripción debe tener menos de 500 caracteres']"
                >
                </v-textarea>
              </v-col>
            </v-row>
        </v-form>
      </template>
    </ConfirmDialog>
    <InfoDialog
      :dialog="infoDialog"
      @close="() => {infoDialog = false}"
    >
      <template slot='content'>
        <v-row justify="center">
          <v-data-table
            dense
            :headers="tableHeaders"
            :items="areaList"
            item-key="name"
            class="elevation-1"
            sortable
            hide-default-footer
          >
          </v-data-table>
        </v-row>
      </template>
    </InfoDialog>
    <Validity
      :dialog="validityDialog"
      :contract="contract"
      @close="validityDialog = false"
      @update-info="updateDetailFiles"
    >
    </Validity>
    <Share
      :dialog="shareDialog"
      :contract="contract"
      @close="closeShareDialog()"
    />
    <SideNotifications ref="sideNotificationRef" :contractId="contract.iddocument" />
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import moment from 'moment'

/* Components */
import Loader from '@/components/Base/Loader'
import DetailCards from './DetailCards'
import ConfirmDialog from '../Base/ConfirmDialog'
import { colors } from '@/utils/colors'
import InfoDialog from './InfoDialog'
import Validity from './Validity'
import Share from './Share'
import SideNotifications from './SideNotifications'

/* Mixins */
import permissionValidate from '@/mixins/permissionValidate'
import expirationColor from '@/mixins/expirationColor'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractDetail',
  components: {
    Loader,
    DetailCards,
    ConfirmDialog,
    InfoDialog,
    Validity,
    Share,
    SideNotifications
  },

  mixins: [permissionValidate, expirationColor],

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      contract: {},
      addendums: [],
      addendum: {},
      attachments: [],
      files: [],
      isLoading: false,
      today: moment().format('YYYY/MM/DD'),
      endDate: '',
      reminderDate: '',
      colorClass: '',
      updateButtonLoader: false,
      rejectionList: [],
      statusList: [],
      showRejectJustifications: false,
      statusDialog: false,
      statusForm: {
        rejectionItem: null,
        statusItem: null,
        observation: ''
      },
      formIsValid: false,
      infoDialog: false,
      areaList: [],
      tableHeaders: [
        { text: 'Área', value: 'name', sortable: false },
        { text: 'Estado', value: 'status', sortable: false },
        { text: 'Actualizado el', value: 'updated', sortable: false },
        { text: 'Usuario', value: 'user', sortable: false }
      ],
      validityDialog: false,
      shareDialog: false,
      userProfile: ''
    }
  },
  watch: {
    statusForm: {
      handler (val) {
        if (val.statusItem !== undefined && val.statusItem != null) {
          if (val.statusItem.name === 'Cancelado') {
            this.showRejectJustifications = true
            this.formIsValid = false
            if (val.statusItem !== null && val.rejectionItem !== null && val.observation !== '') {
              this.formIsValid = true
            }
          } else {
            this.showRejectJustifications = false
            this.formIsValid = true
          }
        } else {
          this.showRejectJustifications = false
          this.formIsValid = false
        }
      },
      deep: true
    },
    showAddendumInfo () {
    }
  },
  computed: {
    ...mapState('contract', ['contracts', 'showAddendumInfo'])
  },

  created () {
    this.userProfile = localStorage.getItem('profile')
    this.isLoading = true
    this.getContractInfo()
    this.getRejectionStatusList()
    Bus.$on('update-attach-files', () => {
      this.getContractInfo()
    })
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR'
    }),
    ...mapMutations('contract',
      { setShowAddendumInfo: 'SET_SHOW_ADDENDUM_INFO' }
    ),
    ...mapActions('contract', [
      'getDocumentDetail',
      'getAllStatus',
      'getRejectionStatus',
      'updateStatusReview'
    ]),

    changeContractState () {
      this.getAllStatus()
        .then(response => {
          this.statusList = response.data
          this.statusDialog = true
          this.statusList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          this.statusList = this.statusList.filter(item => item.idstatusreview !== this.contract.idAreaReview)
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },

    updateState () {
      this.isLoading = true

      let request = {}

      if (this.statusForm.statusItem.name === 'Cancelado') {
        request = {
          document: this.contract.iddocument,
          status: this.statusForm.statusItem.idstatusreview,
          observation: this.statusForm.observation,
          justification: this.statusForm.rejectionItem.idjustification
        }
      } else {
        request = {
          document: this.contract.iddocument,
          status: this.statusForm.statusItem.idstatusreview
        }
      }
      this.updateStatusReview(request)
        .then(response => {
          this.getContractInfo()
          this.isLoading = false
          this.setSnackbar({
            text: response.data.detail,
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response)
          if (error.response.data) {
            this.setSnackbar({
              text: error.response.data.detail,
              timeout: 8000,
              showing: true,
              color: colors.error
            })
          }
        })
      this.closeDialog()
    },

    closeDialog () {
      this.statusDialog = false
      this.statusForm.statusItem = null
      this.statusForm.rejectionItem = null
      this.statusForm.observation = ''
      this.formIsValid = false
    },

    updateDetailFiles () {
      this.getContractInfo()
    },

    getContractInfo () {
      this.getDocumentDetail(this.id)
        .then(response => {
          this.contract = response.data.contract
          this.areaList = response.data.contract.areas
          this.addendums = response.data.addendums
          this.addendum = response.data.addendum
          this.attachments = response.data.attachments
          this.files = response.data.files
          this.isLoading = false
          if (this.contract.idstatus === 4) {
            this.endDate = moment(this.contract.endDate).format('YYYY/MM/DD')
            this.reminderDate = moment(this.contract.reminderDate).format('YYYY/MM/DD')
            this.whichColorIsThis()
          }
          if (this.addendums.length > 0) {
            this.setShowAddendumInfo(true)
          } else {
            this.setShowAddendumInfo(false)
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },

    getRejectionStatusList () {
      this.getRejectionStatus()
        .then(response => {
          this.rejectionList = response.data
          Bus.$emit('create-detail-channel', this.id)
        })
    },

    showCmProposal () {
      window.open(this.contract.commercialFile, '_blank')
    },

    setBackToPending () {
      if (this.contract.status !== 'Cancelado' && this.contract.status !== 'Finalizado' &&
        (this.contract.status !== 'Aprobado' || this.isLegalProfile())) {
        return true
      } else {
        return false
      }
    },

    closeShareDialog () {
      this.shareDialog = false
      this.getContractInfo()
    },

    signAndCloseOrUpdate () {
      return this.contract.status !== 'Finalizado' ? 'Firmar y cerrar' : 'Actualizar vigencias'
    },

    showDrawerNotifications () {
      this.contract.notifications = 0
      this.$refs.sideNotificationRef.obtainContractNotifications()
    }
  }
}
</script>

<style lang="scss" type="scoped">
@import '../../assets/css/expirationCard';

.detail-container {
  padding: 20px 30px;

  .detail-content {
    padding: 10px;
  }
}

.currentMention {
  background: transparent;
}

.pointer {
  cursor: pointer;
}

.my-folders-text {
  color: gray;
}

.padding-0 {
  padding-left: 0;
  padding-top: 0;
}

.card-state {
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 10px;
}

.align-icon {
  vertical-align: text-top;
}

.expiration-border {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.change-state {
  color: #435CC8;
  cursor: pointer;
}
.change-state:active {
    color: rgb(189, 189, 255);
    cursor: wait;
}

.centerH4 {
  justify-content: center;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
  font-size:16px;
}

.contractDetailHead {
  font-size: 14px;
  font-weight: normal;
}
</style>
