<template>
  <div class="content-container">
    <v-row>
      <v-col cols="4" class="questions-panel">
        <div v-for="question in content.questions" :key="question.idquestion">
          <label class="form-label">
            {{ question.name }}
          </label>
          <v-text-field
            type="number"
            v-if="question.typeQuestion === 'number'"
            v-model="answers[question.index]"
            flat
            solo
            outlined
            @input="onChangeInput(question.index)"
            onkeydown="return event.keyCode !== 69"
          >
          </v-text-field>
          <v-select
            v-else-if="question.typeQuestion === 'list'"
            solo
            outlined
            flat
            multiple
            v-model="answers[question.index]"
            :items="question.answerOptions"
            item-text="value"
            item-value="value"
            @input="onChangeInput(question.index)"
          >
          </v-select>
          <v-menu
            ref="menuGeneration"
            v-else-if="question.typeQuestion === 'date'"
            v-model="menus[question.index]"
            transition="scale-transition"
            :close-on-content-click="false"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                flat
                v-model="answers[question.index]"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                @change="onChangeInput(question.index)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="answers[question.index]"
              @input="onChangeInput(question.index, true)"
            >
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-if="question.typeQuestion === 'text'"
            v-model="answers[question.index]"
            flat
            solo
            outlined
            @input="onChangeInput(question.index)"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="8">
        <div class="pre-visualization-container" id="previsualization-container" v-html="content.fullContent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'ContractContent',

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      menus: {},
      date: '',
      answers: {}
    }
  },

  methods: {
    ...mapMutations('contract', {
      setCurrentContractFullContent: 'SET_CURRENT_CONTRACT_FULLCONTENT'
    }),

    initializeQuestions () {
      this.content.questions.forEach(element => {
        this.answers[element.index] = ''
      })
    },

    onChangeInput (value, isDate = false) {
      const elements = document.querySelectorAll(`[data-mention-id="${value}"]`)

      elements.forEach(element => {
        if (typeof this.answers[value] === 'string') {
          element.innerHTML = this.answers[value]
        } else if (Array.isArray(this.answers[value])) {
          element.innerHTML = this.answers[value].join(', ')
        } else if (isDate) {
          element.innerHTML = this.answers[value].toLocaleDateString()
        }
        element.classList.add('currentMention')
        element.classList.remove('mention')
        if (isDate) this.menus[value] = false
        const content = document.getElementById('previsualization-container').innerHTML
        this.setCurrentContractFullContent(content)
        element.scrollIntoView()
      })
    }
  }
}
</script>

<style lang="scss">

.content-container {
  margin: 0 20px 80px 20px;
}
.pre-visualization-container {
  padding: 0 15px;
  font-size: 16px;

  .currentMention {
    background: yellow;
  }
}

.questions-panel {
  position: fixed;
  height: 40vw;
  overflow-y: auto;
  padding-bottom: 5rem;
}

</style>
