<template>
  <div>
    <div class="uploader-container" v-if="this.files.length === 0">
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="auto">
          <label :class="{ 'disabledText': !isActive, 'file-text': true }" >
            {{ labelText }}
          </label>
        </v-col>
        <v-col cols="auto" v-if="withSwitch">
          <v-switch
            v-model="isActive"
            inset
            dense
            flat
          ></v-switch>
        </v-col>
      </v-row>
      <div class="file-container" :disabled="!isActive">
        <v-btn block :ripple="false" color="white" @click="addFiles" class="uploader" depressed>
          <div class="btn-container">
            <v-row no-gutters align="center" justify="center" class="mb-2">
              <v-col cols="auto">
                <icon name="upload" :height="20" :width="20" />
              </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="auto">
                <p>Formato {{ getExtension }}</p>
              </v-col>
            </v-row>
          </div>
        </v-btn>
        <input type="file" ref="files" hidden :accept="validateFolderExtension()" @change="handleFilesUpload()"/>
      </div>
    </div>
    <div class="files-list-container" v-else>
      <div class="files-list">
        <div v-for="(file, key) in files" class="file-listing" :key="file.name">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto">
              <icon :name="validateExtension(file)" :height="70" :width="40" />
            </v-col>
            <v-col cols="8" class="file-detail">
              <p class="file-name">{{ file.name }}</p>
              <p class="file-description">
                Archivo cargado
              </p>
            </v-col>
            <v-col cols="auto">
              <div class="remove-file text-center" @click="removeFile( key )">
                <icon name="close" :height="28" :width="28" />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <slot name="observation" />
      <v-btn v-if="!cmProposal && !isValidity" block color="primary default-btn mt-4" @click="submitFile" :loading="isLoading">Aceptar</v-btn>
    </div>
  </div>
</template>

<script>

/* Components */
import Icon from './Icon'
/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'FileUploader',
  components: {
    Icon
  },

  props: {
    extension: {
      type: String,
      default: () => 'pdf',
      validator: (value) => ['pdf', 'docx', 'docx, doc', 'pdf, doc, docx'].indexOf(value) !== -1
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    withSwitch: {
      type: Boolean,
      default: () => false
    },
    labelText: {
      type: String,
      default: () => 'Seleccione el archivo'
    },
    cmProposal: {
      type: Boolean,
      default: () => false
    },
    detailFileContract: {
      type: Boolean,
      default: () => false
    },
    isValidity: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      files: [],
      isActive: true,
      isLoading: false
    }
  },

  computed: {
    getExtension () {
      return `${this.extension.toUpperCase()}`
    }
  },

  methods: {

    addFiles () {
      this.$refs.files.click()
    },

    handleFilesUpload () {
      const uploadFile = this.$refs.files.files
      this.files.push(uploadFile[0])
      if (this.cmProposal) {
        Bus.$emit('upload-file', this.files)
      } else if (this.isValidity) {
        this.$emit('upload-file', this.files)
      }
    },

    removeFile (key) {
      this.files.splice(key, 1)
    },

    loading (value) {
      this.isLoading = value
    },

    cleanFiles () {
      this.files = []
    },

    submitFile () {
      if (this.detailFileContract) {
        this.$emit('submit-detail-file', this.files[0])
        this.cleanFiles()
      } else {
        this.$emit('upload-file', this.files)
      }
    },

    validateFolderExtension () {
      let extensions = this.extension.replace(/\s/g, '').split(',')
      extensions = extensions.map(item => `.${item}`)
      return extensions
    },

    validateExtension (file) {
      // const nameFile = file.name
      const fileExtension = file.name.split('.').pop()
      const extensions = this.extension.replace(/\s/g, '').split(',')
      let response = extensions[0]

      extensions.forEach(element => {
        if (element === fileExtension) {
          response = element
        }
      })
      return response
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables';

.file-container {
  height: 96px;
  background: white;
  box-sizing: border-box;
  border: 1px dashed var(--v-primary-base);
  border-radius: 8px;
  padding: 1px;
  margin-top: 5px;

  .uploader {
    height: 100% !important;
    text-transform: none;
    font-size: 12px;
    color: #9E9BB2;
  }
}

.file-container[disabled] {
  pointer-events: none;
  opacity: .8;
  border: 1px dashed gray;
}

.file-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--v-dark-base);
}

.file-detail {
  padding: 10px !important;
}

.file-name {
  margin: 0 !important;
  font-weight: 500;
  font-size: 16px;
  color: var(--v-dark-base);
}

.file-description {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #9E9BB2;
}

.remove-file {
  border: 0.5px solid var(--v-primary-base);
  box-sizing: border-box;
  border-radius: 6px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.files-list {
  background: white;
  box-sizing: border-box;
  border: 1px dashed var(--v-primary-base);
  border-radius: 8px;
}

.disabledText {
  color: gray;
}

</style>
