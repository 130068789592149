<template>
  <div>
    <v-navigation-drawer
      v-model="showDrawer"
      absolute
      bottom
      temporary
      :right="true"
      @input="changeState"
    >
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          class="pt-3 pb-0 px-2"
          v-model="textArea"
          label="Escriba su observación"
          :hide-details="true"
          outlined
          >
        </v-textarea>
      </v-col>
      <v-col class="my-2 center-btn" cols="12" sm="12" md="12">
        <v-btn
          class="primary default-btn"
          :disabled="addObservationLoader || textArea === ''"
          :loading="addObservationLoader"
          @click="addObservation()"
        >
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="scroll-detail-obs hide-scroll">
      <div class="my-2" v-for="observation in observations" :key="observation.idobservation">
        <v-divider />
        <v-row class="mt-3" no-gutters>
          <v-col class="px-2 py-0" cols="12" sm="12" md="12">
            <span> {{ observation.content }} </span>
          </v-col>
          <v-col class="px-2 py-0" cols="12" sm="12" md="12">
            <span class="sub-info"> Por {{ observation.user }} - {{ observation.created }} </span>
          </v-col>
        </v-row>
      </div>
    </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { colors } from '@/utils/colors'
/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'Observations',
  components: {},
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    group: null,
    showDrawer: false,
    textArea: '',
    addObservationLoader: false,
    observations: []
  }),
  watch: {
    drawer () {
      this.showDrawer = this.drawer
    }
  },
  created () {
    Bus.$on('show-contract-observations', () => {
      this.showDrawer = this.drawer
      this.obtainObservations()
    })
  },
  computed: {
    ...mapState('contract', ['drawer'])
  },
  methods: {
    ...mapMutations('contract', { setDrawer: 'SET_DRAWER' }),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),
    ...mapActions('contract', ['saveObservation', 'getObservations']),

    changeState (event) {
      this.setDrawer(event)
    },

    addObservation () {
      const request = {
        content: this.textArea,
        document: this.id
      }
      this.addObservationLoader = true
      this.saveObservation(request)
        .then(response => {
          this.textArea = ''
          this.obtainObservations()
          this.setSnackbar({
            text: 'Observación guardada',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          console.log('error:', error)
          this.addObservationLoader = false
          this.setSnackbar({
            text: 'No se pudo agregar la observación',
            timeout: 3000,
            showing: true,
            color: colors.error
          })
        })
    },

    obtainObservations () {
      const request = {
        document: this.id
      }
      this.getObservations(request)
        .then(response => {
          this.observations = response.data
          this.addObservationLoader = false
        })
        .catch(() => {
          this.addObservationLoader = false
        })
    }
  }
}
</script>

<style>
.center-btn {
  text-align: center;
}
.scroll-detail-obs {
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
