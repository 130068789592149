<template>
  <v-footer color="white" fixed class="dialog-footer" elevation="5" padless>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="6">
        <slot name="footer-content" />
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

.dialog-footer {
  height: 80px;
}

@media screen and (max-width: 1380px) {
  .dialog-footer {
    height: 60px;
  }
}

</style>
