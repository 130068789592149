<template>
  <div>
    <v-dialog
      max-width='550px'
      v-model="dialog"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="centered-dialog__card pt-10">
        <slot name="content" />
        <v-card-actions>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto" class="pt-6">
              <v-btn
                class="pb-0"
                depressed
                color="default-btn primary"
                @click="$emit('close')"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  name: 'infoDialog',
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
  }),
  watch: {
  },
  created () {

  },
  computed: {
  },

  methods: {
  }
}
</script>
<style lang="scss">
.centered-dialog__card {
  padding: 20px;
  margin: 0;
  overflow: hidden;
}
</style>
