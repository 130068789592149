<template>
  <div>
    <v-dialog
      max-width='600px'
      v-model="dialog"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="centered-dialog__card">
        <slot name="content" />
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn
                depressed
                color="default-btn primary"
                @click="$emit('close')"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="primary default-btn"
                :disabled="!formValid"
                :loading="buttonLoader"
                @click="$emit(functionToCall)"
              >
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  name: 'confirmDialog',
  props: {
    title: {
      type: String,
      default: () => 'Cancelar Proceso'
    },
    dialog: {
      type: Boolean,
      default: () => false
    },
    functionToCall: {
      type: String,
      default: () => ''
    },
    formValid: {
      type: Boolean,
      default: () => false
    },
    buttonLoader: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    items: [],
    foderSearch: ''
  }),
  watch: {
  },
  created () {

  },
  computed: {
  },

  methods: {
  }
}
</script>
<style lang="scss">
.centered-dialog__card {
  padding: 20px;
  margin: 0;
  overflow: hidden;
}
</style>
