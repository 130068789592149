const getEmptyTemplate = () => {
  return {
    idTemplate: null,
    configurationForm: {
      templateName: '',
      typeTemplate: '',
      templateCountry: '',
      isValid: false
    },
    content: {
      questionnaire: {
        questions: [],
        isValid: true
      },
      editorContent: ''
    },
    deletedQuestions: [],
    deletedOptions: []
  }
}

const getEmptyContract = () => {
  return {
    idContract: null,
    form: {
      isValid: true,
      typeContract: '',
      contractName: ''
    },
    content: {
      fullContent: '',
      questions: []
    }
  }
}

export {
  getEmptyTemplate,
  getEmptyContract
}
