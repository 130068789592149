import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container"},[_c(VRow,[_c(VCol,{staticClass:"questions-panel",attrs:{"cols":"4"}},_vm._l((_vm.content.questions),function(question){return _c('div',{key:question.idquestion},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(question.name)+" ")]),(question.typeQuestion === 'number')?_c(VTextField,{attrs:{"type":"number","flat":"","solo":"","outlined":"","onkeydown":"return event.keyCode !== 69"},on:{"input":function($event){return _vm.onChangeInput(question.index)}},model:{value:(_vm.answers[question.index]),callback:function ($$v) {_vm.$set(_vm.answers, question.index, $$v)},expression:"answers[question.index]"}}):(question.typeQuestion === 'list')?_c(VSelect,{attrs:{"solo":"","outlined":"","flat":"","multiple":"","items":question.answerOptions,"item-text":"value","item-value":"value"},on:{"input":function($event){return _vm.onChangeInput(question.index)}},model:{value:(_vm.answers[question.index]),callback:function ($$v) {_vm.$set(_vm.answers, question.index, $$v)},expression:"answers[question.index]"}}):(question.typeQuestion === 'date')?_c(VMenu,{ref:"menuGeneration",refInFor:true,attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"solo":"","flat":"","outlined":"","readonly":""},on:{"change":function($event){return _vm.onChangeInput(question.index)}},model:{value:(_vm.answers[question.index]),callback:function ($$v) {_vm.$set(_vm.answers, question.index, $$v)},expression:"answers[question.index]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menus[question.index]),callback:function ($$v) {_vm.$set(_vm.menus, question.index, $$v)},expression:"menus[question.index]"}},[_c(VDatePicker,{on:{"input":function($event){return _vm.onChangeInput(question.index, true)}},model:{value:(_vm.answers[question.index]),callback:function ($$v) {_vm.$set(_vm.answers, question.index, $$v)},expression:"answers[question.index]"}})],1):_vm._e(),(question.typeQuestion === 'text')?_c(VTextField,{attrs:{"flat":"","solo":"","outlined":""},on:{"input":function($event){return _vm.onChangeInput(question.index)}},model:{value:(_vm.answers[question.index]),callback:function ($$v) {_vm.$set(_vm.answers, question.index, $$v)},expression:"answers[question.index]"}}):_vm._e()],1)}),0),_c(VSpacer),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"pre-visualization-container",attrs:{"id":"previsualization-container"},domProps:{"innerHTML":_vm._s(_vm.content.fullContent)}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }