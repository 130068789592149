<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      no-click-animation
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          :class="{ 'add-attached': isActivatorText, 'default-btn': true }"
          :text="isActivatorText"
          :icon="isIcon"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <slot name="activator-text" v-if="!isLoading" />
          <v-progress-circular
            v-else
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </template>
      <v-card flat rounded class="dialog__card">
        <slot name="content" :close="closeDialog" :reset="reset" />
        <v-dialog
          v-model="centeredDialog"
          max-width="500"
        >
          <v-card class="centered-dialog__card">
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <h4>{{ title }}</h4>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <p class="caption font-weight-bold">{{ description }}</p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="default-btn primary"
                  @click="centeredDialog = false"
                >
                  No
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  depressed
                  dark
                  color="primary default-btn"
                  @click="closeCenteredDialog"
                >
                  Sí
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

/* Bus */
import Bus from '@/helpers/bus'

/* Utils */
import { getEmptyTemplate, getEmptyContract } from '@/utils/emptyTemplate'

export default {
  name: 'FullscreenDialog',

  props: {
    title: {
      type: String,
      default: () => 'Cancelar Proceso'
    },
    isEditing: {
      type: Boolean,
      default: () => false
    },
    isCreatingAttach: {
      type: Boolean,
      default: () => false
    },
    isCreatingAddendum: {
      type: Boolean,
      default: () => false
    },
    contractTemplate: {
      type: Object,
      default: () => null
    },
    idTemplate: {
      type: String
    },
    description: {
      type: String,
      default: () => '¿Está seguro que desea cancelar el proceso?'
    },
    isActivatorText: {
      type: Boolean,
      default: () => false
    },
    isIcon: {
      type: Boolean,
      default: () => false
    },
    generateFromTemplate: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },

  data () {
    return {
      dialog: false,
      centeredDialog: false,
      reset: false,
      isLoading: false
    }
  },

  watch: {
    dialog (value) {
      if (value) {
        this.setCurrentTemplate(getEmptyTemplate())
        Bus.$emit('reset-stepper')
        Bus.$emit('reset-current-tab')
        Bus.$emit('reset-editor-default')
        Bus.$emit('reset-configuration-form')
        if (this.isEditing && this.idTemplate) {
          this.isLoading = true

          this.getTemplate({ template_id: this.idTemplate })
            .then(response => {
              this.buildCurrentTemplate(response.data, true)
            })

          this.setCurrentId(this.idTemplate)
          this.isLoading = false
        } else if (this.isCreatingAttach) {
          const configuration = {
            templateName: '',
            typeTemplate: this.contractTemplate.templateType,
            templateCountry: this.contractTemplate.country,
            isValid: true
          }
          this.setConfigurationForm(configuration)
        } else {
          if (!this.generateFromTemplate) {
            this.setCurrentContract(getEmptyContract())
          } else {
            this.setCurrentContractName()
          }
          Bus.$emit('reset-contract-form')
        }
      }
    }
  },

  mounted () {
    Bus.$on('close-creation-dialog', () => {
      this.dialog = false
      this.centeredDialog = false
      Bus.$emit('change-detail-template')
    })

    Bus.$on('close-generation-dialog', (createFromAttach) => {
      if (!createFromAttach) {
        this.dialog = false
        this.centeredDialog = false
        if (this.folderSelection.id === undefined) {
          this.getDocuments()
        } else {
          this.getContractsFromFolder()
        }
      } else {
        this.dialog = false
        this.centeredDialog = false
      }
    })
  },

  computed: {
    ...mapState(['folderSelection'])
  },
  created () {
  },
  methods: {
    ...mapMutations('template', {
      setConfigurationForm: 'SET_CONFIGURATION_FORM',
      setQuestionnaire: 'SET_QUESTIONNAIRE',
      setEditorContent: 'SET_EDITOR_CONTENT',
      setCurrentTemplate: 'SET_CURRENT_TEMPLATE',
      setCurrentId: 'SET_CURRENT_ID'
    }),
    ...mapMutations('contract', {
      setCurrentContract: 'SET_CURRENT_CONTRACT',
      setCurrentContractName: 'SET_CURRENT_CONTRACT_NAME'
    }),
    ...mapActions('template', ['getTemplate', 'getQuestionsTemplate']),
    ...mapActions('contract', ['getDocuments']),

    buildCurrentTemplate (data) {
      const configuration = {
        templateName: data.title,
        typeTemplate: data.templateType,
        templateCountry: data.templateCountry,
        isValid: true
      }
      this.setConfigurationForm(configuration)
      this.setEditorContent({ content: data.content })

      data.questions.map(question => {
        question.isValid = true
        question.isAdding = false
        question.newOption = ''

        if (question.typeQuestion !== 'list') {
          question.answerOptions = []
        }
      })
      const questionnaire = {
        questions: data.questions,
        isValid: true
      }
      this.setQuestionnaire(questionnaire)
    },

    closeDialog () {
      this.centeredDialog = true
    },

    closeCenteredDialog () {
      this.centeredDialog = false
      this.dialog = false
      Bus.$emit('update-attach-files')
    },

    getContractsFromFolder () {
      Bus.$emit('folder-selected-change-filter', 0)
      Bus.$emit('folder-selected-change-filter', this.folderSelection.id)
      Bus.$emit('folder-selected', this.folderSelection)
      Bus.$emit('folder-selected-change-country', this.folderSelection.country)
    }
  }
}
</script>

<style>

.dialog__card {
  background: var(--v-background-base) !important;
}

.centered-dialog__card {
  padding: 20px;
  margin: 0;
  overflow: hidden;
}

.add-attached {
  text-transform: none;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
}
</style>
