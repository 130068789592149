import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VNavigationDrawer,{attrs:{"absolute":"","bottom":"","temporary":"","right":true},on:{"input":_vm.changeState},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextarea,{staticClass:"pt-3 pb-0 px-2",attrs:{"label":"Escriba su observación","hide-details":true,"outlined":""},model:{value:(_vm.textArea),callback:function ($$v) {_vm.textArea=$$v},expression:"textArea"}})],1),_c(VCol,{staticClass:"my-2 center-btn",attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VBtn,{staticClass:"primary default-btn",attrs:{"disabled":_vm.addObservationLoader || _vm.textArea === '',"loading":_vm.addObservationLoader},on:{"click":function($event){return _vm.addObservation()}}},[_vm._v(" Agregar ")])],1)],1),_c(VRow,{staticClass:"scroll-detail-obs hide-scroll",attrs:{"no-gutters":""}},_vm._l((_vm.observations),function(observation){return _c('div',{key:observation.idobservation,staticClass:"my-2"},[_c(VDivider),_c(VRow,{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"px-2 py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',[_vm._v(" "+_vm._s(observation.content)+" ")])]),_c(VCol,{staticClass:"px-2 py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"sub-info"},[_vm._v(" Por "+_vm._s(observation.user)+" - "+_vm._s(observation.created)+" ")])])],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }