<template>
  <img :src="require(`@/assets/icons/editor/${this.name}.svg`)" :height="this.height" :width="this.width" alt="">
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },

    height: {
      type: Number,
      default: () => 12
    },

    width: {
      type: Number,
      default: () => 12
    }
  }
}
</script>
