<template>
  <div>
    <FullscreenDialog :is-icon="isIcon" :generateFromTemplate="generateFromTemplate">
      <template slot="activator-text">
        <slot name="text"/>
      </template>
      <template slot="content" slot-scope="{close}">
        <v-snackbar
          v-model="snackbar.showing"
          :color="snackbar.color"
          light
          text
          outlined
          :timeout="snackbar.timeout"
          top
          right
        >
          {{ snackbar.text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              icon
              :color="snackbar.color"
              class="ma-2"
              v-bind="attrs"
              @click="closeSnackbar"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <div class="fill-height dialog-content">
          <v-row align="center" justify="space-around">
            <v-col md="auto">
              <v-btn icon color="black"  @click="close">
                <img src="@/assets/icons/ic_keyboard_normal.svg">
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col md="2">
              <h4 class="dialog-title">{{ getHeaderTitle }}</h4>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-stepper v-model="stepper" light>
            <v-stepper-header>
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="auto">
                  <v-stepper-step
                    :complete="stepper > 1"
                    :editable="stepper > 1"
                    step="1"
                    color="background"
                  >
                    1. Configuración
                  </v-stepper-step>
                </v-col>
                <v-col cols="auto">
                  <v-stepper-step
                    color="dark"
                    step="2"
                  >
                    2. Contenido
                  </v-stepper-step>
                </v-col>
              </v-row>
            </v-stepper-header>
            <v-stepper-content step="1">
              <ContractConfiguration
                :form="currentContract.form"
                :type="getTemplateType"
                :createFromAttach="createFromAttach"
                :createFromAddendum="createFromAddendum"
                :attachFromDetail="attachFromDetail"
                :generateFromTemplate="generateFromTemplate"
              >
              </ContractConfiguration>
            </v-stepper-content>
            <v-stepper-content step="2">
              <ContractContent v-if="!isLoading" ref="generationContent" :content="currentContract.content"></ContractContent>
              <div v-else class="loading-container">
                <Loader type="single" />
              </div>
            </v-stepper-content>
          </v-stepper>
          <Footer>
            <template slot="footer-content">
              <v-row justify="center">
                <v-col cols="auto">
                  <div v-if="stepper == 2">
                    <v-btn large class="default-btn" color="primary" @click="generateContract" :disabled="isSaving" :loading="isSaving">Generar</v-btn>
                  </div>
                  <div v-else>
                    <v-btn large class="default-btn" color="primary" :disabled="getIsValidForm"  @click="next">Siguiente</v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
          </Footer>
        </div>
      </template>
    </FullscreenDialog>
  </div>
</template>

<script>
import { getEmptyContract } from '@/utils/emptyTemplate'
import { colors } from '@/utils/colors'

import { mapActions, mapMutations, mapState } from 'vuex'

import FullscreenDialog from '@/components/Base/FullscreenDialog'
import Loader from '@/components/Base/Loader'
import Footer from '@/components/Base/Footer'
import ContractContent from './Content'
import ContractConfiguration from './Configuration'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractGenerationDialog',
  components: {
    FullscreenDialog,
    Footer,
    Loader,
    ContractContent,
    ContractConfiguration
  },

  props: {
    template: {
      type: Object,
      required: false
    },
    generateFromTemplate: {
      type: Boolean,
      required: false,
      default: () => false
    },
    attachFromDetail: {
      type: Object,
      required: false,
      default: () => {}
    },
    createFromAttach: {
      type: Boolean,
      default: () => false
    },
    createFromAddendum: {
      type: Boolean,
      default: () => false
    },
    isIcon: {
      type: Boolean,
      default: () => false
    },
    attachIdForAddendum: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      stepper: 1,
      isSaving: false,
      dialogVisualization: false,
      isLoading: false,
      snackbar: {
        showing: false,
        absolute: false,
        text: '',
        timeout: 1000,
        color: 'success'
      }
    }
  },

  computed: {
    ...mapState('contract', ['currentContract']),

    getHeaderTitle () {
      return 'Generar'
    },

    getIsValidForm () {
      return !this.currentContract.form.isValid
    },

    getTemplateType () {
      return this.template ? this.template.template : null
    }
  },

  created () {
    Bus.$on('reset-stepper', () => {
      this.stepper = 1
    })
    this.setCurrentContract(getEmptyContract())
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR'
    }),
    ...mapMutations('contract', {
      setCurrentContractContent: 'SET_CURRENT_CONTRACT_CONTENT',
      setCurrentContract: 'SET_CURRENT_CONTRACT'
    }),
    ...mapActions('template', ['getTemplate']),
    ...mapActions('contract', ['saveContractDocument']),

    next () {
      let params = {}
      if (this.createFromAttach || this.createFromAddendum) {
        params = {
          template_id: this.currentContract.form.template.idtemplate
        }
      } else if (this.generateFromTemplate) {
        params = {
          template_type: this.currentContract.form.typeContract,
          template_id: this.$route.params.id
        }
      } else {
        params = {
          // country_template: localStorage.getItem('country'),
          template_type: this.currentContract.form.typeContract,
          template_id: this.currentContract.form.template.idtemplate
        }
      }

      this.isLoading = true
      this.getTemplate(params)
        .then(response => {
          this.setCurrentContractContent({
            fullContent: response.data.content,
            questions: response.data.questions,
            templateCountry: response.data.templateCountry
          })
          this.isLoading = false
          this.stepper = 2
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.snackbar = {
            text: 'No hay una plantilla configurada para el tipo seleccionado',
            timeout: 3000,
            showing: true,
            absolute: true,
            color: colors.warning
          }
        })
      this.$refs.generationContent.initializeQuestions()
    },

    closeSnackbar () {
      this.snackbar.showing = false
    },
    generateContract () {
      this.isSaving = true
      let folderId = this.currentContract.form.folder
      if (folderId === undefined || folderId === '') {
        folderId = 0
      }

      const formData = new FormData()

      if (this.createFromAttach || this.createFromAddendum) {
        formData.append('document', this.attachFromDetail.idDocumentToAttach)
        formData.append('title', this.currentContract.form.contractName)
        formData.append('content', this.currentContract.content.fullContent)
        formData.append('templateType', this.currentContract.form.typeContract)
        formData.append('country', this.currentContract.content.templateCountry)
        // formData.append('sharedUsers', [])
        formData.append('areas', [])
        formData.append('idtemplate', this.generateFromTemplate ? this.$route.params.id : this.currentContract.form.template.idtemplate)
        formData.append('folder', this.attachFromDetail.idFolder ? this.attachFromDetail.idFolder : 0)
      } else {
        formData.append('title', this.currentContract.form.contractName)
        formData.append('content', this.currentContract.content.fullContent)
        formData.append('templateType', this.currentContract.form.typeContract)
        formData.append('country', this.currentContract.content.templateCountry)
        // formData.append('sharedUsers', [])
        formData.append('areas', this.currentContract.form.isFinances ? this.currentContract.form.financeUsers : [])
        formData.append('idtemplate', this.generateFromTemplate ? this.$route.params.id : this.currentContract.form.template.idtemplate)
        formData.append('folder', folderId !== null ? folderId : 0)
        formData.append('file', this.currentContract.form.cmProposal ? this.currentContract.form.cmProposal[0] : [])
      }

      if (this.currentContract.form.shareToManagers !== undefined) {
        if (this.currentContract.form.shareToManagers.length > 0) {
          formData.append('sharedUsers', this.currentContract.form.shareToManagers.toString())
        }
      }

      this.saveContractDocument(formData)
        .then(response => {
          this.isSaving = false
          this.setCurrentContract(getEmptyContract())
          if (this.createFromAttach || this.createFromAddendum) {
            Bus.$emit('update-attach-files')
          }
          Bus.$emit('close-generation-dialog', this.createFromAttach || this.createFromAddendum)
          Bus.$emit('refresh-left-folders')

          this.setSnackbar({
            text: 'Petición realizada exitosamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          console.log(error)
          /* this.setSnackbar({
            text: error.response.data.detail,
            timeout: 3000,
            showing: true,
            color: colors.primary
          })
          */
          this.isSaving = false
        })
    }
  }
}
</script>

<style lang="scss">

.dialog-content {
  background-color: var(--v-background-base);
  padding: 30px 30px 0 30px;

  .dialog-title {
    margin-left: 20px;
  }

  .v-tab {
    text-transform: none;
    font-weight: 500;
    font-size: 20px;
  }

  .v-stepper {
    box-shadow: none;
    padding: 0 !important;
    overflow-y: auto;
    background: var(--v-background-base);
  }

  .v-stepper__header {
    background: var(--v-background-base);
    height: max-content;
  }

  .v-stepper__content {
    padding: 0;
    margin: 0;
    background: var(--v-background-base);
  }

  .v-stepper__step {
    .v-stepper__label {
      font-size: 20px;
      font-weight: 500;
      color: gray;
      line-height: 150%;
      text-shadow: none !important;
    }

    .v-stepper__step__step {
      display: none;
    }
  }

  .v-stepper__step--editable.v-stepper__step--complete {
    .v-stepper__label {
      color: gray !important;
    }
  }

  .v-stepper__step--editable:hover {
    background: transparent !important;
  }

  .v-stepper__step--active {
    .v-stepper__label {
      color: var(--v-dark-base);
    }
  }
}

@media screen and (max-width: 1920px) {
  .dialog-content {
    padding: 5px 12px;
    .v-stepper__header {
      max-height: max-content;
    }

    .v-stepper__step {
      .v-stepper__label {
        font-size: 18px;
        height: 20px;
      }
    }

    .v-tab {
      font-size: 18px;
    }

    .v-stepper__content {
      margin-bottom: 60px;
    }
  }
}
</style>
