<template>
  <div>
    <v-dialog
      max-width='750px'
      v-model="dialog"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="centered-dialog__card">
        <v-card-text class="text-center">
            <h4 class="pa-8 centerH4">Compartir contrato</h4>
            <span>
              Ingresa correos electrónicos para dar permisos de acceso al documento
            </span>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <h5>Agregar email</h5>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <h5>Medio para compartir</h5>
              </v-col>
            </v-row>
            <v-card class="centered-dialog__card" :loading="cardLoader">
              <v-card-text class="scroll-default">
                <div v-if="allShares.length > 0">
                  <v-form ref="shareEmailsForm" v-model="buttonDisabled">
                    <div v-for="(shareItem, index) in allShares" :key="index">
                      <v-row align="center" no-gutters>
                        <v-col class="pr-1" cols="12" sm="6" md="6">
                          <v-text-field
                          v-model="shareItem.email"
                          :rules="emailRules"
                          label="Email"
                          :disabled="shareItem.disabled"
                          outlined
                          />
                        </v-col>
                        <v-col class="pl-1" cols="12" sm="5" md="5">
                          <v-select
                            v-model="shareItem.sharedType"
                            :items="shareOptions"
                            :disabled="shareItem.disabled"
                            item-text="name"
                            item-value="sharedType"
                            outlined
                          />
                        </v-col>
                        <v-col class="pl-1" cols="12" sm="1" md="1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="pb-8"
                                  :color="shareItem.color"
                                  @click="setDeleteItems(shareItem)"
                                  v-bind="attrs"
                                  v-on="on"
                                > mdi-delete-outline </v-icon>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </div>
                <div v-else>
                  <h5>Aún no se ha compartido el contrato</h5>
                </div>
              </v-card-text>
              <v-card-actions>
                <span class="subtitle-action" @click="addNewItem()">
                  + Agregar
                </span>
              </v-card-actions>
            </v-card>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn
                depressed
                color="default-btn primary"
                @click="closeDialog()"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="primary default-btn"
                :disabled="!buttonDisabled"
                :loading="buttonLoader"
                @click="saveShares()"
              >
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '../../../src/utils/colors'

export default {
  name: 'shareDialog',
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    items: [],
    foderSearch: '',
    buttonDisabled: false,
    buttonLoader: false,
    emailRules: [
      v => !v || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'El email debe ser válido'
    ],
    shareOptions: [
      { name: 'Acceso temporal', sharedType: 1 },
      { name: 'Envío por correo', sharedType: 2 }
    ],
    emailShares: [],
    tokenShares: [],
    deleteShares: [],
    allShares: [],
    cardLoader: false

  }),
  watch: {
    allShares: {
      handler (val) {
        // this.$emit('filters', val)
        this.emailShares = val.filter(item => item.sharedType === 2 && item.color !== 'error')
        this.emailShares = this.emailShares.map(item => item.email)
        this.tokenShares = val.filter(item => item.sharedType === 1 && item.email !== '' && item.color !== 'error')
        this.tokenShares = this.tokenShares.map(item => item.email)
      },
      deep: true
    },
    dialog: {
      handler (val) {
        if (val) {
          this.obtainShareUsers()
        }
      }
    }
  },
  created () {
  },
  computed: {
  },

  methods: {
    ...mapActions('contract', ['getSharedUsers', 'shareDocument']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    addNewItem () {
      this.allShares.unshift({ email: '', sharedType: 1, color: 'primary', disabled: false })
    },

    obtainShareUsers () {
      const request = {
        document: this.contract.iddocument
      }
      this.cardLoader = true
      this.getSharedUsers(request)
        .then(response => {
          this.allShares = response.data
          this.cardLoader = false
        })
        .catch(() => {
          this.cardLoader = false
        })
    },

    saveShares () {
      // delete undefined items
      const deleteUsers = this.deleteShares.filter(item => item !== undefined)

      const request = {
        newUsers: this.tokenShares,
        temporalUsers: this.emailShares,
        document: this.contract.iddocument,
        country: this.contract.country,
        deleteUsers
      }
      this.buttonLoader = true
      this.shareDocument(request)
        .then(response => {
          this.buttonLoader = false
          this.obtainShareUsers()
          this.deleteShares = []
          this.setSnackbar({
            text: response.data.detail,
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          this.buttonLoader = false
          this.setSnackbar({
            text: error.response.detail,
            timeout: 3000,
            showing: true,
            color: colors.error
          })
        })
    },

    setDeleteItems (shareItem) {
      if (shareItem.color === 'primary') {
        shareItem.color = 'error'
        this.deleteShares.unshift(shareItem.idexternaluser)
      } else {
        shareItem.color = 'primary'
        this.deleteShares.shift(shareItem.idexternaluser)
      }
      // this.allShares.splice(index, 1)
      return true
    },

    closeDialog () {
      this.$emit('close')
      this.deleteShares = []
    }
  }
}
</script>
<style lang="scss" scoped>
.centered-dialog__card {
  padding: 5px;
  margin: 0;
  overflow: hidden;
}

.centerH4 {
  justify-content: center;
}

.v-icon.v-icon::after {
  background-color: transparent;
}
</style>
