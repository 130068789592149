export default {

  methods: {
    whichColorIsThis () {
      if (this.today < this.reminderDate) {
        this.colorClass = {
          strong: 'first-green',
          ligth: 'second-green',
          color: 'expiration-text-green',
          text: `Vigente: ${this.contract.endDate}`
        }
      } else if (this.today >= this.reminderDate && this.today <= this.endDate) {
        this.colorClass = {
          strong: 'first-yellow',
          ligth: 'second-yellow',
          color: 'expiration-text-yellow',
          text: `Próximo a vencer: ${this.contract.endDate}`
        }
      } else if (this.today > this.endDate) {
        this.colorClass = {
          strong: 'first-red',
          ligth: 'second-red',
          color: 'expiration-text-red',
          text: `Expirado: ${this.contract.endDate}`
        }
      }
    }
  }
}
