<template>
  <div>
    <v-row>
      <v-col class="pa-0" cols="12" :sm="showAddendumInfo.show ? 4 : 6" :md="showAddendumInfo.show ? 4 : 6">
        <v-card class="center py-4 mx-3">
          <v-row no-gutters justify="space-between">
            <v-spacer />
            <v-col cols="12" sm="4" md="4">
              <v-row no-gutters class="place-content" align="center">
                <v-col cols="12" sm="12" md="12">
                  <span class="title-label"> Contrato </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <span class="subtitle-action" @click="showObservations()" > Observaciones </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pl-8" cols="12" sm="4" md="4">
              <ContractGenerationDialog
                v-if="contract.status === 'Finalizado' && !isClientProfile()"
                :createFromAddendum="true"
                :attachFromDetail="attachFromDetail"
                :key="reloadGenerationDialog"
                :is-icon="true"
              >
                <template slot="text">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="#1E1041"> mdi-book-plus </v-icon>
                    </template>
                    <span>Generar adenda</span>
                  </v-tooltip>
                </template>
              </ContractGenerationDialog>
            </v-col>
          </v-row>
          <v-col class="pa-4 scroll-detail hide-scroll" cols="12" sm="12" md="12">
            <div class="files-list-detail px-4">
              <div v-for="(file, key) in files" class="file-listing" :key="file.name">
                <v-row no-gutters justify="space-between" align="center">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <v-row class="place-content" no-gutters align="center">
                      <v-col cols="auto">
                        <icon :name="getExtensionFiles(key)" :height="70" :width="40" />
                      </v-col>
                      <v-col class="file-detail pb-0" cols="12" xs="6" sm="6" md="9">
                        <p class="file-name text-truncate">{{ file.name }}</p>
                        <p class="file-date">
                          {{file.uploadDate}} por {{file.user}}
                        </p>
                      </v-col>
                      <v-col cols="auto">
                        <div class="download-file text-center" @click="showFile( key )">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="#1E1041"> mdi-download </v-icon>
                            </template>
                            <span>Descargar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <h6 class="file-description">{{file.observation}}</h6>
                  </v-col>
                </v-row>
                <v-row class="ma-4">
                  <v-divider></v-divider>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="12" :sm="showAddendumInfo.show ? 4 : 6" :md="showAddendumInfo.show ? 4 : 6">
        <v-card class="center py-4 mx-3">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <span class="title-label"> Anexos ({{ attachments.length }}) </span>
              </v-col>
              <v-col class="pa-4 scroll-detail hide-scroll" cols="12" sm="12" md="12">
                <div class="files-list-detail px-4" v-if="attachments.length > 0">
                  <div v-for="(attachment, key) in attachments" class="file-listing" :key="attachment.name">
                    <v-row no-gutters justify="space-between" align="center">
                      <v-col cols="12">
                        <v-row class="place-content" no-gutters align="center">
                          <v-col cols="auto">
                            <icon :name="getExtensionAttach(key)" :height="70" :width="40" />
                          </v-col>
                          <v-col class="file-detail pb-0" cols="12" sm="6" md="9">
                            <p class="file-name">{{ attachment.name }}</p>
                            <p class="file-date">
                              {{attachment.uploadDate}} por {{attachment.user}}
                            </p>
                          </v-col>
                          <v-col cols="auto">
                            <ContractGenerationDialog
                              v-if="contract.status === 'Finalizado' && !isClientProfile()"
                              :createFromAddendum="true"
                              :attachFromDetail="attachFromDetail"
                              :attachIdForAddendum="attachment.iddocument"
                              :key="reloadGenerationDialog"
                              :is-icon="true"
                            >
                              <template slot="text">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-book-plus </v-icon>
                                  </template>
                                  <span>Generar adenda</span>
                                </v-tooltip>
                              </template>
                            </ContractGenerationDialog>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <h6 class="file-description">{{attachment.observation}}</h6>
                      </v-col>
                      <v-row class="place-content mt-2" no-gutters align="center">
                          <v-col class="mx-1" cols="auto">
                            <div class="download-file text-center" @click="showAttachFile( key )">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-download </v-icon>
                                </template>
                                <span>Descargar</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                          <v-col class="mx-1" cols="auto" v-if="attachment.show_detail > 0">
                            <div class="download-file text-center" @click="obtainDocumentsAttached( key, false )">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-eye </v-icon>
                                </template>
                                <span>Ver adjuntos</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                          <v-col class="mx-1" cols="auto">
                            <div
                              class="download-file text-center"
                              @click="() => {fileUploadDialog = true, isAttach = attachment.iddocument}"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-note-plus </v-icon>
                                </template>
                                <span>Agregar adjunto</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                        </v-row>
                    </v-row>
                    <v-row class="ma-4">
                      <v-divider></v-divider>
                    </v-row>
                  </div>
                </div>
                <div v-else>
                  <span class="title-label"> El contrato no tiene anexos </span>
                </div>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="12" sm="4" md="4" v-if="showAddendumInfo.show">
        <v-card class="center py-4 mx-3">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <span class="title-label"> Adendas ({{ addendums.length }})</span>
              </v-col>
              <v-col class="pa-4 scroll-detail hide-scroll" cols="12" sm="12" md="12">
                <div class="files-list-detail px-4" v-if="addendums.length > 0">
                  <div v-for="(addendum, key) in addendums" class="file-listing" :key="addendum.name">
                    <v-row no-gutters justify="space-between" align="center">
                      <v-col cols="12">
                        <v-row class="place-content" no-gutters align="center">
                          <v-col cols="auto">
                            <icon :name="getExtensionAddendum(key)" :height="70" :width="40" />
                          </v-col>
                          <v-col class="file-detail pb-0" cols="12" sm="6" md="9">
                            <p class="file-name">{{ addendum.name }}</p>
                            <p class="file-date">
                              {{addendum.uploadDate}} por {{addendum.user}}
                            </p>
                          </v-col>
                          <v-col cols="auto">
                            <div class="text-center">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> {{ addendum.icon }} </v-icon>
                                </template>
                                <span>Estado general: {{ addendum.status }}</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <h6 class="file-description">{{addendum.addendumObservation}}</h6>
                      </v-col>
                      <v-row class="place-content mt-2" no-gutters align="center">
                        <v-col class="mx-1" cols="auto">
                          <div class="download-file text-center" @click="showAddendumFile( key )">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#1E1041"> mdi-download </v-icon>
                              </template>
                              <span>Descargar</span>
                            </v-tooltip>
                          </div>
                        </v-col>
                        <v-col class="mx-1" cols="auto" v-if="addendum.show_detail > 0">
                            <div class="download-file text-center" @click="obtainDocumentsAttached( key, true )">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-eye </v-icon>
                                </template>
                                <span>Ver adjuntos</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                          <v-col class="mx-1" cols="auto">
                            <div
                              class="download-file text-center"
                              @click="() => {fileUploadDialog = true, isAddendum = addendum.iddocument}"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-note-plus </v-icon>
                                </template>
                                <span>Agregar adjunto</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                          <v-col class="mx-1" cols="auto">
                            <div
                              class="download-file text-center"
                              @click="obtainAddendumStatus(addendum)"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="#1E1041"> mdi-pencil-outline </v-icon>
                                </template>
                                <span>Actualizar estado</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                      </v-row>
                    </v-row>
                    <v-row class="ma-4">
                      <v-divider></v-divider>
                    </v-row>
                  </div>
                </div>
                <div v-else>
                  <span class="title-label"> El contrato no tiene adendas </span>
                </div>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="align-btn"
      align="center"
      justify="center"
      v-if="validateFileActions()"
    >
      <v-col cols="12" :sm="showAddendumInfo.show ? 4 : 6" :md="showAddendumInfo.show ? 4 : 6">
        <v-btn
          class="default-btn"
          color="primary"
          @click="() => {fileUploadDialog = true, isAttach = 0}"
        >
          Agregar contrato
        </v-btn>
      </v-col>
      <v-col cols="12" :sm="showAddendumInfo.show ? 4 : 6" :md="showAddendumInfo.show ? 4 : 6">
        <ContractGenerationDialog
          :createFromAttach="true"
          :attachFromDetail="attachFromDetail"
          :key="reloadGenerationDialog"
        >
          <template slot="text">
            Agregar anexo
          </template>
        </ContractGenerationDialog>
      </v-col>
      <v-col cols="12" sm="3" md="4">
      </v-col>
    </v-row>
    <v-dialog
      v-model="fileUploadDialog"
      max-width="600"
      @click:outside="() => {showDocumentsAttached = false, this.clearDocumentsAttachedUploader()}"
      @keydown.esc="() => {showDocumentsAttached = false, this.clearDocumentsAttachedUploader()}"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center centered-dialog__card">
          <h3> Sube el archivo </h3>
        </v-card-title>
        <v-card-text>
          <FileUploader
            ref="fileUploader"
            :detailFileContract="true"
            extension="docx, doc"
            :multiple="false"
            @submit-detail-file="uploadDetailFile($event)"
          >
             <template slot='observation'>
               <br>
              <span class="form-label file-text"> Agregar observación </span>
              <v-textarea
                class="form-field observation mt-2"
                label="Escribe una observación opcional"
                v-model="observation"
                outlined
                single-line
                background-color="white"
              >
              </v-textarea>
             </template>
          </FileUploader>
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="uploadDetailFile"
          >
            Aceptar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <Observations :id="contract.iddocument" />
    <InfoDialog
      :dialog="showDocumentsAttached"
      @close="() => {showDocumentsAttached = false}"
    >
      <template slot='content'>
        <v-row justify="center">
          <v-col class="scroll-detail">
            <div v-for="(documentAttached, key) in documentsAttachedList" class="file-listing" :key="documentAttached.name">
              <v-row no-gutters justify="space-between" align="center">
                <v-col cols="12"  sm="12" md="12">
                  <v-row class="place-content" no-gutters align="center">
                    <v-col cols="auto">
                      <icon :name="getDocumentsAttachedList(key)" :height="70" :width="40" />
                    </v-col>
                    <v-col class="file-detail pb-0" cols="12" sm="6" md="9">
                      <p class="file-name">{{ documentAttached.name }}</p>
                      <p class="file-date">
                        {{documentAttached.uploadDate}} por {{documentAttached.user}}
                      </p>
                    </v-col>
                    <v-col cols="auto">
                      <div class="download-file text-center" @click="showDocumentsAttachedFiles( key )">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="#1E1041"> mdi-download </v-icon>
                          </template>
                          <span>Descargar</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <h6 class="file-description">{{documentAttached.observation}}</h6>
                </v-col>
              </v-row>
              <v-row class="ma-3">
                <v-divider></v-divider>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </template>
    </InfoDialog>
    <ConfirmDialog
      functionToCall='setAddendumState'
      :dialog="showAddendumStatusDialog"
      :formValid="statusValue != null"
      :buttonLoader="confirmDialogButtonLoader"
      @close="closeAddendumStatusDialog"
      @setAddendumState="setAddendumState()"
    >
      <template slot='content'>
        <v-row class="my-6" justify="center">
          <v-data-table
            dense
            :headers="addendumStatusReviewListHeaders"
            :items="addendumStatusReviewList"
            item-key="name"
            class="elevation-1"
            sortable
            :loading="addendumStatusTableLoader"
            hide-default-footer
          >
          </v-data-table>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12" md="12" class="pl-16 pr-16 pt-4 pb-2">
            <span class="file-name"> Actualizar estado de adenda </span>
            <v-select
              :items="statusList"
              item-text="name"
              item-value="idstatusreview"
              v-model="statusValue"
              label="Seleccione estado"
              solo
              outlined
              clearable
              dense
              clear-icon="mdi-close-circle"
              flat
              hide-details="auto"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import Icon from '../Base/Icon'
import FileUploader from '@/components/Base/FileUploader'
import { mapActions, mapMutations, mapState } from 'vuex'
import ContractGenerationDialog from '@/components/Contract/GenerationDialog'
import Observations from '@/components/Contract/Observations'
import InfoDialog from '@/components/Contract/InfoDialog'
import ConfirmDialog from '../Base/ConfirmDialog'
import { colors } from '../../../src/utils/colors'

/* Bus */
import Bus from '@/helpers/bus'

/* Mixins */
import permissionValidate from '@/mixins/permissionValidate'

export default {
  name: 'DetailCard',
  components: {
    Icon,
    FileUploader,
    ContractGenerationDialog,
    Observations,
    InfoDialog,
    ConfirmDialog
  },

  mixins: [permissionValidate],

  props: {
    contract: {
      type: Object,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    attachments: {
      type: Array,
      required: true
    },
    addendums: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      extension: 'pdf',
      lorem: '"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500"',
      fileUploadDialog: false,
      observation: '',
      isAttach: 0,
      attachFromDetail: {},
      reloadGenerationDialog: false,
      isAddendum: 0,
      showDocumentsAttached: false,
      documentsAttachedList: [],
      showAddendumStatusDialog: false,
      addendumStatusReviewList: [],
      addendumStatusReviewListHeaders: [
        { text: 'Área', value: 'area', sortable: false },
        { text: 'Estado', value: 'status', sortable: false },
        { text: 'Actualizado el', value: 'uploadDate', sortable: false },
        { text: 'Usuario', value: 'user', sortable: false }
      ],
      statusList: [],
      statusValue: null,
      addendumStatusTableLoader: false,
      addendumInfoItem: {},
      confirmDialogButtonLoader: false
    }
  },
  watch: {
  },
  computed: {
    ...mapState('contract', ['drawer', 'showAddendumInfo'])
  },

  created () {
    this.attachFromDetail = {
      idCategory: this.contract.idTemplateContract,
      idDocumentToAttach: this.contract.iddocument,
      idFolder: this.contract.idfolder,
      idAttach: 0
    }
    Bus.$on('update-attach-files', () => {
      this.reloadGenerationDialog = !this.reloadGenerationDialog
    })
  },

  methods: {
    ...mapActions('contract', ['uploadContractFile', 'getAttachmentTemplates',
      'getDocumentsAttached', 'getDocumentStatusReview', 'setDocumentStatusReview', 'getAllStatus']),
    ...mapMutations('contract', { setDrawer: 'SET_DRAWER' }),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    showFile (key) {
      window.open(this.files[key].url, '_blank')
    },

    showAttachFile (key) {
      window.open(this.attachments[key].url, '_blank')
    },

    showAddendumFile (key) {
      window.open(this.addendums[key].url, '_blank')
    },

    showDocumentsAttachedFiles (key) {
      let url = this.documentsAttachedList[key].file
      const isHomeIka = url.includes('home/ikatech/')
      if (isHomeIka) {
        url = url.replace('home/ikatech/', '')
      }
      window.open(url, '_blank')
    },

    getExtensionFiles (key) {
      return `${this.files[key].name.split('.').pop()}`
    },

    getExtensionAttach (key) {
      return `${this.attachments[key].name.split('.').pop()}`
    },
    getExtensionAddendum (key) {
      return `${this.addendums[key].name.split('.').pop()}`
    },
    getDocumentsAttachedList (key) {
      return `${this.documentsAttachedList[key].name.split('.').pop()}`
    },

    uploadDetailFile (file) {
      this.fileUploadDialog = false
      const request = {
        file: file,
        document: this.contract.iddocument,
        attach: this.isAttach,
        observation: this.observation,
        addendum: this.isAddendum
      }

      this.uploadContractFile(request)
        .then(response => {
          this.$emit('update-detail-files')
          this.clearDocumentsAttachedUploader()
          this.setSnackbar({
            text: 'Archivo guardado correctamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(() => {
          this.setSnackbar({
            text: 'No se pudo guardar el archivo',
            timeout: 3000,
            showing: true,
            color: colors.error
          })
        })
    },

    clearDocumentsAttachedUploader () {
      this.isAttach = 0
      this.isAddendum = 0
      this.file = []
      this.observation = ''
      this.$refs.fileUploader.cleanFiles()
    },

    updateAttachFiles () {
      this.reloadGenerationDialog = !this.reloadGenerationDialog
    },

    validateFileActions () {
      if ((this.contract.status !== 'Cancelado' && this.contract.status !== 'Finalizado' &&
      this.contract.status !== 'Aprobado') || (this.isLegalProfile() && this.contract.status !== 'Cancelado')) {
        return true
      } else {
        return false
      }
    },

    showObservations () {
      this.setDrawer(true)
      Bus.$emit('show-contract-observations')
    },

    obtainDocumentsAttached (key, attachedDocumentIsAddendum) {
      let request = {}
      if (attachedDocumentIsAddendum) {
        request = {
          addendum: attachedDocumentIsAddendum,
          document: this.addendums[key].iddocument
        }
      } else {
        request = {
          document: this.attachments[key].iddocument
        }
      }
      this.getDocumentsAttached(request)
        .then(response => {
          this.showDocumentsAttached = true
          this.documentsAttachedList = response.data
        })
        .catch(() => {
          this.showDocumentsAttached = false
        })
    },

    obtainAddendumStatus (addendum) {
      this.addendumInfoItem = addendum
      this.addendumStatusTableLoader = true
      this.showAddendumStatusDialog = true
      this.getDocumentStatusReview({ document: addendum.iddocument })
        .then(response => {
          this.addendumStatusReviewList = response.data
          this.addendumStatusTableLoader = false
        })

      this.getAllStatus()
        .then(response => {
          this.statusList = response.data
          this.statusList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          // this.statusList = this.statusList.filter(item => item.idstatusreview !== addendum.idstatus)
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },

    closeAddendumStatusDialog () {
      this.showAddendumStatusDialog = false
      this.statusList = []
      this.addendumStatusReviewList = []
      this.statusValue = null
    },

    setAddendumState () {
      this.confirmDialogButtonLoader = true
      this.setDocumentStatusReview({ document: this.addendumInfoItem.iddocument, status: this.statusValue.idstatusreview })
        .then(() => {
          this.confirmDialogButtonLoader = false
          this.obtainAddendumStatus(this.addendumInfoItem)
          this.$emit('update-detail-files')
          this.setSnackbar({
            text: 'Adenda actualizada correctamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          this.confirmDialogButtonLoader = false
          if (error.response.status === 400) {
            this.setSnackbar({
              text: error.response.data.detail,
              timeout: 3000,
              showing: true,
              color: colors.error
            })
          } else {
            this.setSnackbar({
              text: 'No se pudo actualizar el estado de la adenda',
              timeout: 3000,
              showing: true,
              color: colors.error
            })
          }
        })
    }
  }
}
</script>

<style>
.title-label {
    color: var(--v-dark-base);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
}

.subtittle-label {
  color: #435CC8;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.subtittle-label:active {
    color: rgb(189, 189, 255);
    cursor: wait;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--v-dark-base);
}

.file-detail {
  padding: 10px !important;
}

.file-name {
  margin: 0 !important;
  font-weight: 500;
  font-size: 16px;
  color: var(--v-dark-base);
}

.file-date {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #9E9BB2;
}

.download-file {
  border: 0.5px solid var(--v-primary-base);
  box-sizing: border-box;
  border-radius: 6px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.files-list-detail {
  background: #F8F8F8;
  text-align: left !important;
}

.icon-color {
  color: #1E1041;
}

.file-description {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: #7E7E7E;
  text-align: left;
  padding-left: 16px;
}

.observation {
  height: 200px;
}

.scroll-detail {
  min-height: 250px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.align-btn {
  text-align-last: center;
}

.place-content {
  place-content: center;
}
.absolute {
  position: absolute;
}

</style>
